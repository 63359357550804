import React, { Component, useState } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import axios from 'axios'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  SEO,
  TextField,
} from '../components'
import { up } from '../lib/styles'
import Link from '../components/Link'
import links from '../lib/links'

const PageWrapper = styled.div`
  position: relative;
  min-height: calc(min(100vw, 1920px) * (2362 / 1920));
`

const BLink = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const BLinkBlank = styled.a.attrs(() => ({
  target: '_blank',
  rel: 'noopener noreferrer',
}))`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`
const BBBP = 770

const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const lightBlue = '#a7cbeb'
const lighterLightBlue = '#d2e7f8'
const darkBlue = '#1d3b70'
const lighterDarkBlue = '#376190'
const yellow = '#f6ee24'

const StyledOuterBox = styled.div`
  ${({ theme: { colors }, dark }) => css`
    background: ${dark ? lighterDarkBlue : lighterLightBlue};
  `}
`

const StyledInnerBox = styled.div`
  ${({ theme: { colors }, dark }) => css`
    background: ${dark ? darkBlue : lightBlue};
  `}
  display: block;
  padding: 30px 28px;
  width: 100%;

  /* max-width: 400px; */
  ${up('tinyMobile')} {
    width: 90%;
  }

  ${up('mobile')} {
    /* max-width: 500px; */
    padding: 40px 40px;
    width: 80%;
  }
  ${up(BBBP)} {
    max-width: 1250px;
    width: 100%;
  }

  ${up('tablet')} {
    padding: 50px 70px;
  }
  ${up(1050)} {
    width: 85%;
  }
`

const InvestTitle = (props) => (
  <Title.Big
    innerAs="h2"
    fontWeight="400"
    style={{ textTransform: 'uppercase' }}
    black
    {...props}
  />
)

const BlueBox = ({ children, title, dark }) => (
  <StyledOuterBox dark={dark}>
    <StyledInnerBox dark={dark}>
      <Title.Big
        innerAs="h2"
        fontWeight="400"
        style={{ textTransform: 'uppercase' }}
        white={dark}
        black={!dark}
      >
        {title}
      </Title.Big>
      {children}
    </StyledInnerBox>
  </StyledOuterBox>
)

const BlueBoxItem = ({ image, title, children, maxWidth = '250px' }) => (
  <Col width="100%" maxWidth={maxWidth}>
    <Col maxWidth="220px">
      <Img fluid={image.image.fluid} />
    </Col>
    <Gap gap="16px" />
    <Text>{title}</Text>
    {children}
  </Col>
)
const BlueBoxTitle = (props) => <Title.Small innerAs="h3" black {...props} />
const BlueBoxTitleLink = ({ href, to, ...props }) => {
  const LinkComponent = href ? BLinkBlank : BLink
  const linkProps = href ? { href } : { to }
  return (
    <BlueBoxTitle>
      <LinkComponent {...linkProps} {...props} />
    </BlueBoxTitle>
  )
}

const BBRow = (props) => (
  <>
    <Gap gap="50px" mobileGap="28px" bp="tablet" />
    <Row {...props} justifyContent="space-between" responsive bp={BBBP} />
  </>
)

const BBWhiteText = (props) => (
  <Text white gray={false} fontWeight="400" {...props} />
)

const BBList = styled.ul`
  font-size: 20px;
  padding-left: 20px;
  li {
    line-height: 1.35;
  }

  ${up('mobile')} {
    font-size: 22px;
  }
`
const BBGap = () => <Gap gap="28px" />
const BBTextField = styled(TextField)`
  ::placeholder {
    color: #3157a6;
  }
`

const ProgressBarOuter = styled.div`
  ${({ theme: { colors }, progress }) => css`
    background: ${yellow};
    height: 6px;
    width: 100%;
    position: relative;

    &:before {
      content: "€0";
      position: absolute;
      top: 14px;
      left: 0;
      font-size: 16px;
      color: white;
      transform: translateX(-50%);
    }
    &:after {
      content: "€1,500,000";
      font-size: 16px;
      position: absolute;
      top: 14px;
      right: 0;
      color: white;
      transform: translateX(25%);
      ${up('mobile')} {
        transform: translateX(50%);
      }
    }
  `}
`
const ProgressBarInner = styled.div`
  ${({ progress }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: ${progress}%;
    height: 100%;
    background: ${lighterDarkBlue};
    &:after {
      content: "€1,300,000";
      font-size: 16px;
      position: absolute;
      top: -22px;
      right: 0;
      color: white;
      transform: translateX(25%);
      ${up('tablet')} {
        top: 14px;
        transform: translateX(50%);
      }
    }
  `}
`

const TopImageSection = styled(Section)`
  position: absolute;
  bottom: 40px;
  left: 0;
  max-width: unset;
  width: 100%;
  padding: 0 64px;
  ${up('tablet')} {
    /* padding: 0 40px; */
    padding: 0 94px;
    bottom: 80px;
  }
`

const ProgressBar = ({ progress }) => (
  <ProgressBarOuter>
    <ProgressBarInner progress={progress} />
  </ProgressBarOuter>
)

const InvestForm = ({ fieldOnly }) => {
  const [email, setEmail] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [isSending, setIsSending] = useState(false)

  const onEmailSend = async () => {
    if (isSending) return
    if (!email) return setErrorMessage('Email is required')
    if (!validateEmail(email)) return setErrorMessage('Email is not valid')
    setIsSending(true)

    try {
      await axios.post('/api/v1/investor-contact', {
        email: email.trim(),
      })
      setIsSending(false)
      setIsSent(true)

      setEmail('')
      setErrorMessage('')
    } catch (error) {
      setIsSending(false)

      setErrorMessage('')
      setTimeout(
        // call after ui update
        () => alert('Sending failed'),
        1,
      )
    }
  }

  if (isSent) {
    const thankYouText = 'Thank you, we will contact you soon.'
    return !fieldOnly ? <BBWhiteText>{thankYouText}</BBWhiteText> : <Text fontWeight="400">{thankYouText}</Text>
  }
  return (
    <>
      {!fieldOnly && (
        <>
          <BBWhiteText>Get more information:</BBWhiteText>
          <Gap gap="12px" mobileGap="12px" />
        </>
      )}
      <Row responsive maxWidth="600px">
        <BBTextField
          width="100%"
          placeholder="Your e-mail"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          required
          hasError={!!errorMessage}
          error={errorMessage}
          onBlur={() => setErrorMessage('')}
          style={{ border: '3px solid #3157a6', color: '#3157a6' }}
          ErrorComponent={Text.ErrorInvest}
        />
        <Gap gap="8px" mobileGap="20px" />
        <Button.SubmitInvestForm onClick={onEmailSend}>
          SUBMIT
        </Button.SubmitInvestForm>
      </Row>

      {!fieldOnly && (
        <>
          <Gap gap="16px" />

          <BBWhiteText>
            Leave us your contact and we’ll send the information package.
          </BBWhiteText>
        </>
      )}
    </>
  )
}

class Invest extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSent: false,
      email: '',
      errorMessage: '',
    }
  }

  onEmailSend = async () => {
    const { email } = this.state
    if (!email) return this.setState({ errorMessage: 'Email is required' })
    if (!validateEmail(email)) return this.setState({ errorMessage: 'Email is not valid' })
    this.setState({ isSent: true })

    try {
      await axios.post('/api/v1/investor-contact', {
        email: email.trim(),
      })
      this.setState({ email: '', errorMessage: '' })
    } catch (error) {
      this.setState({ isSent: false, errorMessage: '' })
      setTimeout(
        // call after ui update
        () => alert('Sending failed'),
        1,
      )
    }
  };

  render() {
    const { email, isSent, errorMessage } = this.state

    const {
      location: { pathname },
      data: {
        paleFireImage,
        kiwiImage,
        keenSoftwareImage,
        beatSaberImage,
        habermannBartos,
        rastyTurek,
        borisRenski,
        danielMajewski,
        matejTurek,
        topImage,
      },
    } = this.props
    return (
      <PageWrapper>
        <SEO
          title="Invest | Zuri"
          description="We are developing a modern private aircraft with vertical takeoff and landing. This will allow you to affordably travel from city to city at distances of up to 700 km apart."
          pathname={pathname}
        />
        <Gap.Top />

        <Col width="100%" position="relative">
          <Img fluid={topImage.image.fluid} />
          <TopImageSection>
            <Row width="100%">
              <Col maxWidth="1300px" width="100%" margin="auto">
                <Title.Big
                  innerAs="h2"
                  fontWeight="400"
                  style={{ textTransform: 'uppercase', maxWidth: '800px' }}
                  white
                  black={false}
                >
                  Invest in breakthrough technology. Shape&nbsp;tomorrow.
                </Title.Big>
              </Col>
            </Row>
          </TopImageSection>
        </Col>

        <Section background="white">
          <Gap gap="80px" mobileGap="40px" />

          <BlueBox title="Pre-series A now open" dark>
            <Gap gap="24px" mobileGap="24px" bp="tablet" />

            <BBWhiteText>
              GOAL €1,500,000
              <br />
              CURRENT PROGRESS €1,300,000 received already
            </BBWhiteText>

            <Gap gap="24px" />
            <ProgressBar progress={85} />
            <Gap gap="50px" />

            <BBWhiteText>
              Convertible notes
              <br />
              25-35% discount to series A valuation
            </BBWhiteText>

            <Gap gap="32px" mobileGap="28px" />

            <InvestForm />

            <Gap gap="40px" />
          </BlueBox>

          <Gap gap="80px" mobileGap="40px" />

          <BlueBox title="Pre-seed convertible notes">
            <BBRow>
              <BlueBoxItem image={paleFireImage}>
                <BlueBoxTitleLink href="https://palefirecapital.com/en/">
                  Pale Fire Capital
                </BlueBoxTitleLink>
                <BBList>
                  <li>Great European VC</li>
                  <li>
                    Currently controlling
                    {' '}
                    <BLinkBlank href="https://www.groupon.com/">
                      Groupon
                    </BLinkBlank>
                  </li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={kiwiImage}>
                <BlueBoxTitleLink href="https://www.kiwi.com/">
                  Kiwi.com
                </BlueBoxTitleLink>
                <BBList>
                  <li>Large ticket booking platform</li>
                  <li>EBITDA €34M in 2023</li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={keenSoftwareImage}>
                <BlueBoxTitleLink href="https://www.keenswh.com/">
                  Keen Software House
                </BlueBoxTitleLink>
                <BBList>
                  <li>Authors of best&#8209;selling game Space Engineers</li>
                  <li>
                    And GoodAI spinoff focusing on Artificial General
                    Intelligence
                  </li>
                </BBList>
              </BlueBoxItem>
            </BBRow>
          </BlueBox>
          <Gap gap="40px" />

          <Text>
            "At Pale Fire Capital, we enjoy supporting radical projects that
            aren't afraid of big goals and have the potential to go global.
            We&nbsp;believe that the Zuri VTOL aircraft is a bold concept that
            can find its place in the market." - Jan Barta
          </Text>

          <Gap gap="40px" />

          <BlueBox title="Seed round (Autumn 2021)">
            <BBRow>
              <BlueBoxItem image={beatSaberImage} maxWidth="300px">
                <BlueBoxTitleLink href="http://www.jaroslavbeck.com/">
                  Jaroslav Beck
                </BlueBoxTitleLink>
                <BBList>
                  <li>
                    CEO and co-founder of&nbsp;Beat&nbsp;Games (sold to Meta)
                  </li>
                  <li>
                    Currently CEO of
                    {' '}
                    <BLinkBlank href="https://cans.com">CANS</BLinkBlank>
                  </li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={habermannBartos}>
                <BlueBoxTitleLink href="https://www.linkedin.com/in/janhabermann/">
                  Habermann & Bartoš
                </BlueBoxTitleLink>
                <BBList>
                  <li>
                    Founding partners of&nbsp;
                    <BLinkBlank href="https://www.credoventures.com/">Credo Ventures</BLinkBlank>
                  </li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={rastyTurek}>
                <BlueBoxTitleLink to={links.STORY}>
                  And more angels
                </BlueBoxTitleLink>
                <BBList>
                  <li>Rasty Turek (Pex.com)</li>
                  <li>Jakub Nesetril (Apiary)</li>
                  <li>
                    And
                    {' '}
                    <BLink to={links.STORY}>more</BLink>
                  </li>
                </BBList>
              </BlueBoxItem>
            </BBRow>
          </BlueBox>

          <Gap gap="40px" />

          <Text>
            "Efficient transportation is a worldwide challenge which demands
            ambitious solution propositions which alongside legislation changes
            could be easily applicable for the whole world's infrastructure." -
            Jaroslav Beck
          </Text>

          <Gap gap="40px" />

          <BlueBox title="Current pre-series-a (2024)">
            <BBRow>
              <BlueBoxItem image={borisRenski}>
                <BlueBoxTitleLink href="https://www.linkedin.com/in/borisrenski/">Boris Renski</BlueBoxTitleLink>
                <BBList>
                  <li>Founder of FreedomFi</li>
                  <li>Previously co-founder of Mirantis</li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={danielMajewski}>
                <BlueBoxTitleLink href="https://www.linkedin.com/in/danielmajewski/">Daniel Majewski</BlueBoxTitleLink>
                <BBList>
                  <li>Head of Strategy R&D and System Solutions (Global) at ZF Group</li>
                </BBList>
              </BlueBoxItem>

              <BBGap />

              <BlueBoxItem image={matejTurek}>
                <BlueBoxTitleLink href="https://www.linkedin.com/in/mat%C4%9Bj-turek-cfa-a450078a">Matěj Turek</BlueBoxTitleLink>
                <BBList>
                  <li>Founder & Investor</li>
                  <li>
                    <BLinkBlank href="https://www.fond10x.cz/">
                      Fond 10X
                    </BLinkBlank>
                  </li>
                </BBList>
              </BlueBoxItem>
            </BBRow>

            <Gap gap="16px" />
            <Text black fontWeight="400" fontSize="22px" textAlign="right">
              And several other angel investors and entrepreneurs.
            </Text>
          </BlueBox>

          <Gap gap="100px" mobileGap="40px" />
          <Row maxWidth="1300px" margin="auto">
            <Col>
              <InvestTitle>Join us too</InvestTitle>
              <Gap gap="24px" />

              <InvestForm fieldOnly />

              <Gap gap="100px" />
              <InvestTitle>WHY INVEST?</InvestTitle>

              <BBList>
                <li>
                  <Text>
                    <Text.Black>PROVEN CONCEPT</Text.Black>
                    {' '}
                    Successfully
                    flight-tested large-scale demonstrator and 10+ subscale
                    models.
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text.Black>HYBRID APPROACH</Text.Black>
                    {' '}
                    Provides 7x longer
                    usable range than eVTOLs.
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text.Black>VERSATILITY</Text.Black>
                    {' '}
                    All helicopter use
                    cases (passengers, cargo, medivac), just cheaper, quieter
                    and safer.
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text.Black>ATTRACTIVE VALUATION</Text.Black>
                    {' '}
                    Still orders
                    of magnitude smaller compared to Joby or Archer.
                  </Text>
                </li>
                <li>
                  <Text>
                    <Text.Black>TALENTED TEAM</Text.Black>
                    {' '}
                    with a pragmatic and
                    cost-effective approach.
                  </Text>
                </li>
              </BBList>
              <Gap gap="100px" />
            </Col>
          </Row>
        </Section>
      </PageWrapper>
    )
  }
}

export default Invest

export const pageQuery = graphql`
  fragment PreviewImage on File {
    image: childImageSharp {
      fluid(maxWidth: 220, quality: 90) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
  query {
    paleFireImage: file(relativePath: { eq: "images/invest/pale-fire.jpg" }) {
      ...PreviewImage
    }
    kiwiImage: file(relativePath: { eq: "images/invest/kiwi.jpg" }) {
      ...PreviewImage
    }
    keenSoftwareImage: file(
      relativePath: { eq: "images/invest/keen-software.jpg" }
    ) {
      ...PreviewImage
    }
    beatSaberImage: file(relativePath: { eq: "images/invest/beat-saber.jpg" }) {
      ...PreviewImage
    }
    habermannBartos: file(
      relativePath: { eq: "images/invest/habermann-bartos.jpg" }
    ) {
      ...PreviewImage
    }
    rastyTurek: file(relativePath: { eq: "images/invest/rasty-turek.jpg" }) {
      ...PreviewImage
    }
    borisRenski: file(relativePath: { eq: "images/invest/boris-renski.jpg" }) {
      ...PreviewImage
    }
    danielMajewski: file(
      relativePath: { eq: "images/invest/daniel-majewski.jpg" }
    ) {
      ...PreviewImage
    }
    matejTurek: file(relativePath: { eq: "images/invest/matej-turek.jpg" }) {
      ...PreviewImage
    }
    topImage: file(relativePath: { eq: "images/invest/invest-top.jpg" }) {
      image: childImageSharp {
        fluid(maxWidth: 1920, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
